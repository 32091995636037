// -----------------------------------------------------------------------------
// 1 - SETTINGS
// Global variables, site-wide settings, config switches, etc.
// -----------------------------------------------------------------------------
@import '1-settings/bourbon/bourbon';
@import '1-settings/breakpoints';
@import '1-settings/colors';
@import '1-settings/typography';
@import '1-settings/animations';
@import '1-settings/radius';


// -----------------------------------------------------------------------------
// 2 - TOOLS
// Site-wide mixins and functions
// -----------------------------------------------------------------------------
@import '2-tools/functions';
@import '2-tools/mixins';
@import '2-tools/media-queries';
@import '2-tools/placeholders';


// -----------------------------------------------------------------------------
// 3 - GENERIC
// Low-specificity, far-reaching rulesets (e.g. resets)
// -----------------------------------------------------------------------------
@import '3-generic/normalize';
@import '3-generic/box-sizing';


// -----------------------------------------------------------------------------
// 4 - BASE
// Unclassed HTML elements (e.g. a {}, blockquote {}, address {})
// -----------------------------------------------------------------------------
@import '4-base/body';
@import '4-base/forms';
@import '4-base/headings';
@import '4-base/links';
@import '4-base/lists';
@import '4-base/media';
@import '4-base/paragraphs';
@import '4-base/quotes';
@import '4-base/rules';
@import '4-base/tables';
@import '4-base/selections';
@import '4-base/inputs';
@import '4-base/addresses';


// -----------------------------------------------------------------------------
// 5 - PAGE SETTINGS
// Default page settings for printing
// -----------------------------------------------------------------------------
@page { margin: 2cm; }

*,
*:before,
*:after {
  color: #000 !important;
  background: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
}

a,
a:visited {
  text-decoration: underline;
}

a[href]:after {
  content: ' (' attr(href) ')';
}

abbr[title]:after {
  content: ' (' attr(title) ')';
}

a[href^='#']:after,
a[href^='javascript:']:after {
  content: '';
}

pre {
  white-space: pre-wrap !important;
}
pre,
blockquote {
  border: 1px solid #999;
  page-break-inside: avoid;
}

thead {
  display: table-header-group;
}

tr,
img {
  page-break-inside: avoid;
}

p,
h2,
h3 {
  orphans: 3;
  widows: 3;
}

h2,
h3 {
  page-break-after: avoid;
}


// -----------------------------------------------------------------------------
// 5 - CUSTOM PRINT RULES
// Unique settings to optimize pages for printing
// -----------------------------------------------------------------------------

#header-container,
#footer-container,
.shortcut--main,
.shortcut--header,
.pagination,
svg,
img { display: none; }
